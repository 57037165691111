<template>
    <multiselect v-model="localSelected"
                 tag-placeholder="Add this as new tag"
                 placeholder="Search or add a tag"
                 :options="allTags"
                 :multiple="true"
                 :taggable="true"
                 @tag="addTag"
                 @select="(selectedOption, id) => this.$emit('select', selectedOption, id)"
                 @remove="(removedOption, id) => this.$emit('remove', removedOption, id)"
                 :loading="loading"
    />
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import Multiselect from 'vue-multiselect'

    export default {
        name: "TagSelect",
        props: {
            value: {
                type: Array,
                required: true
            },
            loading: Boolean
        },
        watch: {
            value() {
                this.$emit('input', this.value);
            }
        },
        components: {
            Multiselect
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterTags',
            ]),
            localSelected: {
                get() {
                    return this.value
                },
                set(localSelected) {
                    this.$emit('input', localSelected)
                }
            },
            allTags() {
                let reducedTags = [];
                if (this.getCurrentTwitterTags.tags) {
                    this.getCurrentTwitterTags.tags.forEach(tag => {
                        reducedTags.push(tag.name)
                    });
                }
                return reducedTags
            },
        },
        methods: {
            ...mapActions([
                'requestCurrentTwitterTags',
                'addTwitterTag',
            ]),

            addTag(searchQuery, id) {
                this.addTwitterTag({"name": searchQuery}).then(() => {
                    console.log("added Tag", searchQuery);
                    this.localSelected.push(searchQuery);
                    this.$emit('select', searchQuery, id);
                });
                //this.$emit('tag', searchQuery, id)
                // const tag = {
                //     name: newTag,
                // };
                //this.options.push(tag);
                //his.selectedTags.push(tag)
            }

        },
        created() {
            if(!this.getCurrentTwitterTags.tags){
                this.requestCurrentTwitterTags();
            }
        },
    }
</script>

<style scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
